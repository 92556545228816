<script lang="ts">
	import { browser } from '$app/environment';
	import { serialiseSchema, type SupportedSchemaOrgType } from '$lib/schemaOrg';

	export let title: string | null | undefined = null;
	export let metaTitle: string | null | undefined = null;
	export let authorName: string = 'Timo Dechau';
	export let publishedDate: string | null | undefined = null;
	export let description: string | null | undefined = null;
	export let metaDescription: string | null | undefined = null;
	export let ogSiteName: string | null | undefined = 'DeepskyData';
	export let ogType: string | null | undefined = 'website';
	export let ogUrl: string | null | undefined = null;
	export let ogTitle: string | null | undefined = null;
	export let ogDescription: string | null | undefined = null;
	export let ogImage: string | null | undefined = null;
	export let ogImageAlt: string | null | undefined = 'A preview image of a shared DeepskyData link';
	// Fallback image if no ogImage is provided (ogImage is null or undefined = null)
	export let image: string | null | undefined = null;
	export let ogLocale: string | null | undefined = 'en_US';
	export let schema: SupportedSchemaOrgType | null | undefined = null;
	export let useSchemaOrg: boolean = false;

	$: pageTitle = metaTitle ?? `${title} | DeepskyData`;
	$: setTitle(metaTitle ?? pageTitle);

	function setTitle(title: string) {
		if (!browser) return;

		document.title = title;
	}
</script>

<svelte:head>
	{#if metaTitle}
		<title>{metaTitle}</title>
	{:else if pageTitle}
		<title>{pageTitle}</title>
	{/if}
	{#if ogDescription || metaDescription}
		<meta property="og:description" content={ogDescription ?? metaDescription} />
	{/if}
	<meta name="author" content={authorName} />
	{#if publishedDate}
		<meta name="date" property="article:published_time" content={publishedDate} />
	{/if}
	{#if metaDescription || description}
		<meta name="description" content={metaDescription ?? description} />
	{/if}
	<meta property="og:site_name" content={ogSiteName} />
	<meta property="og:type" content={ogType} />
	{#if ogUrl}
		<meta property="og:url" content={ogUrl} />
	{/if}
	{#if ogTitle}
		<meta property="og:title" content={ogTitle} />
	{/if}
	{#if ogImage}
		<meta name="image" property="og:image" content={ogImage} />
	{:else if image}
		<meta name="image" property="og:image" content={image} />
	{/if}
	<meta property="og:image:alt" content={ogImageAlt} />
	<meta property="og:locale" content={ogLocale} />
	{#if useSchemaOrg && schema}
		{@html serialiseSchema(schema)}
	{/if}
</svelte:head>
