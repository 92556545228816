import { get } from 'svelte/store';
import type { CourseDetail$result, CourseLesson$result } from '$houdini';
import { page } from '$app/stores';

export type VideoSchema = {
  "@context": "https://schema.org",
  "@type": "VideoObject",
  name: string,
  description: string,
  thumbnailUrl: string,
  uploadDate?: string,
  duration?: string | null,
  contentUrl: string,
  embedUrl?: string | null,
  publisher: {
    "@type": "Organization",
    name: string,
    logo: {
      "@type": "ImageObject",
      url: string,
      width: number,
      height: number
    }
  },
  author: {
    "@type": "Person",
    name: string
  },
  interactionStatistic?: {
    "@type": "InteractionCounter",
    interactionType: "https://schema.org/WatchAction",
    userInteractionCount: number
  };
}

export type CourseSchema = {
  "@context": "https://schema.org",
  "@type": "Course",
  "name": string,
  "description": string,
  "url": string,
  "image": string,
  "provider": {
    "@type": "Organization",
    "name": string,
    "sameAs": string,
    "logo": string
  },
  "courseCode": string,
  hasCourseInstance?: Array<
    {
      "@type": "CourseInstance",
      "startDate": string,
      "endDate": string,
      "courseMode": string | "online",
      "instructor": {
        "@type": "Person",
        "name": string
      },
      "location": {
        "@type": "Place",
        "name": "Online"
      }
    }>
}

export type SupportedSchemaOrgType = VideoSchema | CourseSchema;

export function serialiseSchema(schema: SupportedSchemaOrgType) {
  return `<script type="application/ld+json">${JSON.stringify(schema, null, 2)}</script>`
}

/**
 * Builds the videoobject schema for a given video.
 */
export function buildVideoSchema(course: CourseLesson$result["course"] | undefined): VideoSchema | null {
  if (!course || !course.name || !course.shortDescription) {
    return null;
  }
  const schema: VideoSchema = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    name: course.name,
    description: course.shortDescription,
    thumbnailUrl: course.thumbnail.url,
    uploadDate: course.updatedAt.toISOString(),
    duration: course.timeToComplete,
    contentUrl: get(page).url.href,
    publisher: {
      "@type": "Organization",
      name: "DeepskyData",
      logo: {
        "@type": "ImageObject",
        url: 'https://deepskydata.com/deepskydata_logo_black.png',
        width: 400,
        height: 205,
      }
    },
    author: {
      "@type": "Person",
      name: course.instructors.map(i => i.name).join(", ")
    }
  }
  // TODO: maybe for the future
  // if (interactionCount) {
  //   schema.interactionStatistic = {
  //     "@type": "InteractionCounter",
  //     interactionType: "https://schema.org/WatchAction",
  //     userInteractionCount: videoInfo.interactionCount
  //   };
  // }
  return schema;
}

/**
 * Builds the course schema for a given course.
 */
export function buildCourseSchema(course: CourseDetail$result["course"] | undefined): CourseSchema | null {
  if (!course || !course.name || !course.shortDescription) {
    return null;
  }

  return {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": course?.name,
    "description": course?.shortDescription,
    "url": get(page).url.href,
    "image": course?.thumbnail.url ?? '',
    "provider": {
      "@type": "Organization",
      "name": "DeepskyData",
      "sameAs": "https://deepskydata.com",
      "logo": "https://deepskydata.com/deepskydata_logo_black.png"
    },
    "courseCode": course?.slug ?? '',
    // hasCourseInstance: [
    //   {
    //     "@type": "CourseInstance",
    //     "startDate": course?.createdAt.toISOString() ?? '',
    //     "endDate": course?.updatedAt.toISOString() ?? '',
    //     "courseMode": "online",
    //     "instructor": {
    //       "@type": "Person",
    //       "name": course?.instructors.join(", ") ?? ''
    //     },
    //     "location": {
    //       "@type": "Place",
    //       "name": "Online"
    //     }
    //   }
    // ]
  }
}
